@font-face {
  font-family: 'NeueHaasGroteskText Pro Md';
  src: url('font/font.woff2') format('woff2'),
    url('font/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
}

.displayFlex {
  display: flex;
  flex-wrap: wrap;
}

.positionRelative {
  position: relative;
}

#cn {
  width: 100%;
  font-family: 'NeueHaasGroteskText Pro Md';
  padding: 1.04vw;
  opacity: 0.0;
}

#cn.start {
  opacity: 1.0;
}

#cn h2 {
  width: 6.30vw;
  font-size: 0.73vw;
  line-height: 0.88vw;
  font-weight: normal;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-right: 10.21vw;
}

#cn .desc {
  width: 15.47vw;
  font-size: 0.73vw;
  line-height: 0.88vw;
}

#cn .desc a {
  color: #000000;
  text-decoration: none;
}

#cn .slideshow {
  width: 48.38vw;
  position: absolute;
  z-index: 2;
  top: 1.04vw;
  right: 1.04vw;
}

#cn .slideshow .swiper-wrapper {
  width: 100%;
}

#cn .slideshow .swiper-slide {
  width: 100%;
  padding-bottom: 75%;
  background: #FFFFFF;
  position: relative;
}

#cn .slideshow .swiper-slide img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0vw;
  left: 0vw;
}

@media screen and (max-width: 375px) {

  #cn {
    padding: 4vw;
  }

  #cn h2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 14.93vw;
    font-size: 3.73vw;
    line-height: 4.8vw;
    position: fixed;
    z-index: 3;
    top: 0vw;
    left: 0vw;
    padding: 4vw;
    background: #FFFFFF;
  }

  #cn .desc {
    width: 100%;
    font-size: 3.73vw;
    line-height: 4.8vw;
    margin-top: 87.7vw;
    padding-top: 14.93vw;
    margin-bottom: 10.93vw;
  }

  #cn .slideshow {
    width: calc(100% - 8vw);
    margin-top: 14.93vw;
    top: 7.8vw;
    right: 4vw;
  }

}
